@import '../../css/mixins.scss';

@keyframes showBackground {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}

@keyframes showLetter {
   from {
      opacity: 0;
      transform: translate(-20px, 30px) rotate(45deg) scale(0.5);
   }
   to {
      opacity: 1;
      transform: translate(0, 0) rotate(0deg) scale(1);
   }
}

.wrapper {
   position: absolute;
   left: 50%;
   top: 80px;
   transform: translateX(-50%);
   color: white;
   font-weight: bold;
   white-space: nowrap;
   max-width: 100%;

   @include screen('xs', 'lg') {
      top: 100px;
   }

}

.content {
   position: relative;
   z-index: 1;
   display: flex;
   font-size: 24px;
   padding: 20px 40px;

   @include screen('xs', 'xs') {
      font-size: 4.5vw;
      padding: 15px 4.5vw;
   }

   .letter {
      display: block;
      will-change: transform;
      position: relative;

      animation-name: showLetter;
      animation-duration: .7s;
      animation-timing-function: ease-out;
      animation-fill-mode: backwards;

   }
}

.background {
   position: absolute;
   z-index: 0;
   background-color: rgba(rgb(26, 25, 25), 0.6);
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 25px;
   filter: blur(8px);

   animation-name: showBackground;
   animation-duration: 2s;
   animation-delay: 0.5s;
   animation-fill-mode: backwards;

   @include screen('xs', 'xs') {
      filter: blur(6px);
      border-radius: 15px;
   }
}