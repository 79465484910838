@import '../../css/variables.scss';

@mixin wrapper {
   padding: 25px;
   display: flex;
   justify-content: center;
}

.link {
   width: 200px;
   height: 200px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border-radius: 15px;
   position: relative;
   color: $backgroundColor;
   text-decoration: none;

   .icon {
      font-size: 50px;
      position: relative;
      z-index: 1;
   }

   &:before {
      content: "";
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      transition: opacity .2s linear;
      opacity: 0;
   }

   &:hover:before {
      opacity: 1;
   }
}

.title {
   position: relative;
   z-index: 1;
   font-size: 18px;
   margin-top: 10px;
}


.wrapperLight {
   @include wrapper();

   .icon {
      color: $backgroundColor;
   }

   .title {
      color: $backgroundColor;
      text-shadow: 2px 2px 3px rgba(black, 1);
   }

   .link:before {
      box-shadow: $navShadow;
      background-color: rgba(black, 0.5);
   }
}

.wrapperDark {
   @include wrapper();

   .icon, .title {
      color: $fontColor;
   }

   .link:before {
      box-shadow: $grayShadow;
      background-color: $backgroundColor;
   }
}

