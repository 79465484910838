@import '../../css/mixins.scss';

.scrollPanel {
   padding: 25px;
   @include screen('xs', 'lg') {
      height: calc(100vh - 50px);
   }
}

.container {
   @include screen('xs', 'lg') {
      padding-bottom: 50px;
   }
}