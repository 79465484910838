@import '../../css/mixins.scss';

.logo {
   width: 100%;
}

.navList {
   list-style: none;
   margin: 0;
   padding: 0;
}

.scrollPanel {
   padding: 25px;
   @include screen('xs', 'lg') {
      max-height: calc(100vh - 50px);
   }

   &Offline {
      padding: 25px;
      @include screen('xs', 'lg') {
         max-height: calc(100vh - 75px);
      }
   }
}

.container {
   @include screen('xs', 'lg') {
      padding-bottom: 50px;
   }
}