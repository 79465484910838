@import '../../css/variables.scss';
@import '../../css/mixins.scss';

@keyframes rotate {
   from {
      transform: rotate(0deg) ;
   }
   to {
      transform: rotate(360deg);
   }
}
@keyframes dot {
   0% {
      opacity: 1;
   }
   20% {
      opacity: 1;
   }
   50% {
      opacity: 0;
   }
   80% {
      opacity: 1;
   }
   100% {
      opacity: 1;
   }
}

.wrapper {
   position: fixed;
   z-index: 11;
   top: 25px;
   left: 25px;
   width: 250px;
   height: 60px;
   border-radius: 40px;
   box-shadow: $navShadow;
   background-color: rgba(black, 0.7);

   @include screen('xs', 'lg') {
      top: 75px;
   }

   &Offline {
      @extend .wrapper;
      top: 50px;
      @include screen('xs', 'lg') {
         top: 100px;
      }
   }
}

.text {
   position: absolute;
   top: 50%;
   left: 70px;
   font-size: 18px;
   color: white;
   transform: translateY(-50%);
   font-family: Arial;

   .dot {
      will-change: transform;
      animation-name: dot;
      animation-duration: 0.6s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      font-weight: bold;
      font-size: 24px;
      line-height: 18px;

      &:nth-child(2) {
         animation-delay: .1s;
      }
      &:nth-child(3) {
         animation-delay: .2s;
      }
   }
}

.circle {
   position: absolute;
   will-change: transform;
   top: 10px;
   left: 10px;
   width: 40px;
   height: 40px;
   border-top: 5px solid $primaryColor;
   border-bottom: 5px solid transparent;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-radius: 50%;
   animation-name: rotate;
   animation-duration: 1s;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
}