@import '../../../css/variables.scss';

.wrapper {
   display: flex;
   flex-wrap: wrap;
   max-height: 75px;
   overflow: hidden;
}

.filter {
   font-size: 12px;
   padding: 10px;
   border-radius: 7.5px;
   background-color: $buttonHoverColor;
   margin-right: 5px;
   margin-bottom: 5px;
   line-height: 15px;
   display: flex;
   align-items: center;
   user-select: none;
   cursor: pointer;

   .icon {
      font-size: 14px;
      line-height: 15px;
      margin-right: 2px;
   }
}