@import '../../../css/variables.scss';

.wrapper {
   background-color: $inputColor;
   border-radius: 7.5px;
   position: relative;
   margin-bottom: 20px;
   margin-top: 5px;
}

.searchIcon {
   position: absolute;
   width: 50px;
   height: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 0;
   right: 0;
   background-color: transparent;
   user-select: none;
   pointer-events: none;

   .icon {
      font-size: 24px;
      color: $fontPlaceholderColor;
   }
}

.input {
   height: 50px;
   width: 100%;
   background-color: transparent;
   border-radius: 7.5px;
   border: none;
   padding: 15px;
   color: $fontColor;
   font-size: 18px;
   outline: none;
   font-family: $font;
   padding-right: 50px;

   &::placeholder {
      color: $fontPlaceholderColor;
   }

   &:focus {
      box-shadow: $focusShadow;
   }
}