@import '../../../css/variables.scss';

.wrapper {
   display: flex;
   justify-content: flex-end;
}

.button {
   background-color: transparent;
   color: $fontColor;
   font-family: $font;
   border: none;
   font-size: 12px;
   display: flex;
   align-items: center;
   padding: 10px;
   transition: background-color .2s linear;
   border-radius: 7.5px;
   cursor: pointer;

   .icon {
      font-size: inherit;
      margin-left: 5px;
      font-size: 14px;
   }

   &:hover {
      background-color: $buttonHoverColor;
   }

}