@import '../../css/variables.scss';

.wrapper {
   position: fixed;
   bottom: 25px;
   right: 25px;
   width: 50px;
   height: 50px;
   background-color: $backgroundColor;
   border: 2px solid $primaryColor;
   border-radius: 7.5px;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 3;
   box-shadow: $navShadow;
   cursor: pointer;
   will-change: transform;
   transition: transform 0.3s ease-out;

   &Hide {
      @extend .wrapper;
      transform: translateY(80px);
   }

   .icon {
      font-size: 20px;
      color: $fontColor;
   }
}
