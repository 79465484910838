@import '../../css/variables.scss';

.wrapper {
   background-color: $primaryColor;
   color: $backgroundColor;
   padding: 25px;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   position: relative;
   z-index: 2;
   font-weight: bold;

   a {
      color: $backgroundColor;
   }
}

.description {
   padding: 10px 0;
}

.links {
   margin: 0;
   padding: 0;
   list-style: none;
   display: flex;
   flex-wrap: wrap;
   padding: 10px 0;
}