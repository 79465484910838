@import '../../css/variables.scss';

.separator {
   background-color: $primaryColor;
   width: 40%;
   position: relative;
   left: 30%;
   height: 2px;
   border-radius: 1px;
   margin: 15px 0;
}