.wrapper {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   font-size: 14px;
   line-height: 25px;
   z-index: 100;
   background-color: red;
   color: white;
   text-align: center;
}