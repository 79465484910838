@import '../../css/variables.scss';
@import '../../css/mixins.scss';

.wrapper {
   color: $fontColor;
   background-color: $backgroundColor;
   display: flex;
   margin-bottom: 25px;
   border-radius: 15px;
   border: 2px solid $primaryColor;
   box-shadow: $navShadow;
   overflow: hidden;
   text-decoration: none;

   @include screen('xs', 'xs') {
      flex-direction: column;
   }

   .thumbnail {
      flex-basis: 240px;
      width: 240px;
      flex-shrink: 0;
      flex-grow: 0;
      overflow: hidden;
      position: relative;

      @include screen('xl', 'xl') {
         flex-basis: 180px;
         width: 180px;
      }
      @include screen('sm', 'sm') {
         flex-basis: 180px;
         width: 180px;
      }

      @include screen('xs', 'xs') {
         width: 100%;
         flex-basis: 200px;
      }
   }

   .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 2s ease-out;
   }

   .content {
      padding: 25px;
      width: 100%;
   }

   .infoBar {
      display: flex;
      align-items: center;

      @include screen('xs', 'xs') {
         flex-direction: column;
         align-items: flex-start;
      }

      .date {
         font-size: 14px;
         color: $primaryColor;
         display: inline;
         font-weight: bold;
      }

      .dot {
         width: 5px;
         height: 5px;
         border-radius: 2.5px;
         background-color: $buttonHoverColor;
         margin: 0 10px;

         @include screen('xs', 'xs') {
            display: none;
         }
      }

      .place {
         font-size: 14px;
         @include onelineDots();
      }
   }

   .title {
      // @include onelineDots();
      font-size: 24px;
      margin-bottom: 10px;
   }

   .description {
      font-size: 14px;
      @include multilineDots(4, 1.3em);

      p {
         margin: 0;
         font-size: inherit;
      }
   }

   .readMore {
      position: relative;
      background-color: $backgroundColor;
      color: $primaryColor;
      font-size: 18px;
      margin-top: 5px;
      text-align: right;
      top: 10px;
      transition: opacity .2s linear;
      opacity: 0;
      font-weight: bold;
   }

   &:hover {
      .image {
         transform: scale(1.1);
      }

      .readMore {
         opacity: 1;
      }
   }
}

