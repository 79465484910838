@mixin onelineDots {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

@mixin multilineDots($lines, $lineHeight) {
   display: -webkit-box;
   -webkit-line-clamp: $lines;
   -webkit-box-orient: vertical;
   overflow: hidden;
   line-height: $lineHeight;
   height: $lines * $lineHeight;
}

@mixin screen($sizeFrom, $sizeTo) {
   $sm: 576px;
   $md: 768px;
   $lg: 992px;
   $xl: 1200px;
   $xxl: 1500px;

   @if $sizeFrom == 'XS' or $sizeFrom == 'xs' or $sizeFrom == 1 {$sizeFrom: 1}
   @else if $sizeFrom == 'SM' or $sizeFrom == 'sm' or $sizeFrom == 2 {$sizeFrom: 2}
   @else if $sizeFrom == 'MD' or $sizeFrom == 'md' or $sizeFrom == 3 {$sizeFrom: 3}
   @else if $sizeFrom == 'LG' or $sizeFrom == 'lg' or $sizeFrom == 4 {$sizeFrom: 4}
   @else if $sizeFrom == 'XL' or $sizeFrom == 'xl' or $sizeFrom == 5 {$sizeFrom: 5}
   @else if $sizeFrom == 'XXL' or $sizeFrom == 'xxl' or $sizeFrom == 6 {$sizeFrom: 6}
   @else {$sizeFrom: 1}

   @if $sizeTo == 'XS' or $sizeTo == 'xs' or $sizeTo == 1 {$sizeTo: 1}
   @else if $sizeTo == 'SM' or $sizeTo == 'sm' or $sizeTo == 2 {$sizeTo: 2}
   @else if $sizeTo == 'MD' or $sizeTo == 'md' or $sizeTo == 3 {$sizeTo: 3}
   @else if $sizeTo == 'LG' or $sizeTo == 'lg' or $sizeTo == 4 {$sizeTo: 4}
   @else if $sizeTo == 'XL' or $sizeTo == 'xl' or $sizeTo == 5 {$sizeTo: 5}
   @else if $sizeTo == 'XXL' or $sizeTo == 'xxl' or $sizeTo == 6 {$sizeTo: 6}
   @else {$sizeTo: 6}

   @if $sizeFrom <= 1 and $sizeTo >= 1 {
      @media (max-width: $sm - 1) {
         @content;
      }
   }

   @if $sizeFrom <= 2 and $sizeTo >= 2 {
      @media (min-width: $sm) and (max-width: $md - 1) {
         @content;
      }
   }

   @if $sizeFrom <= 3 and $sizeTo >= 3 {
      @media (min-width: $md) and (max-width: $lg - 1) {
         @content;
      }
   }

   @if $sizeFrom <= 4 and $sizeTo >= 4 {
      @media (min-width: $lg) and (max-width: $xl - 1) {
         @content;
      }
   }

   @if $sizeFrom <= 5 and $sizeTo >= 5 {
      @media (min-width: $xl) and (max-width: $xxl - 1) {
         @content;
      }
   }

   @if $sizeFrom <= 6 and $sizeTo >= 6 {
      @media (min-width: $xxl) {
         @content;
      }
   }
}