.wrapper {
   position: absolute;
   z-index: 0;
   width: 100%;
   left: 0;
   top: 0;
   height: 320px;
   mask-image: linear-gradient(180deg, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%);

   .imageHeader {
      opacity: 1;
   }
   .imageHeaderHome {
      opacity: 0;
   }
}

.wrapperHome {
   position: fixed;
   z-index: 0;
   width: 100%;
   left: 0;
   top: 0;
   height: 100vh;

   .imageHeaderHome {
      opacity: 1;
   }
   .imageHeader {
      opacity: 0;
   }
}

@mixin imageHeader {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   transition: opacity .3s linear;
}

.imageHeader {
   @include imageHeader();
   &Home {
      @include imageHeader();
   }
}