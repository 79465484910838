@import '../../css/variables.scss';
@import '../../css/mixins.scss';

.wrapper {
   border-radius: 15px;
   position: relative;
   background-color: $backgroundColor;
   border: 2px solid $primaryColor;

   a {
      color: $primaryColor;
   }
}

.headerImage {
   border-top-left-radius: 14px;
   border-top-right-radius: 14px;
   overflow: hidden;
   height: 400px;
   position: relative;
   z-index: 0;
}

.content {
   padding: 50px;

   @include screen('xs', 'xs') {
      padding: 25px;
   }
}

.infoBar {
   display: flex;
   align-items: center;

   @include screen('xs', 'xs') {
      flex-direction: column;
      align-items: flex-start;
   }

   .date {
      font-size: 18px;
      color: $primaryColor;
      display: inline;
      font-weight: bold;
   }

   .dot {
      width: 5px;
      height: 5px;
      border-radius: 2.5px;
      background-color: $buttonHoverColor;
      margin: 0 10px;

      @include screen('xs', 'xs') {
         display: none;
      }
   }

   .place {
      font-size: 18px;
   }
}

.galleryContainer {
   padding-bottom: 15px;
   border-top: 2px solid $primaryColor;
}