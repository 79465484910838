@import '../../../css/variables.scss';

.wrapper {
   position: relative;
}

.label {
   padding: 5px 0;
   font-size: 12px;
   line-height: 20px;
   padding-left: 30px;
   display: block;
   user-select: none;
   cursor: pointer;
}

.radio {
   position: absolute;
   top: 0;
   left: 0;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
}

.checkmark {
   position: absolute;
   top: 50%;
   left: 0;
   pointer-events: none;
   transform: translateY(-50%);
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: 2px solid $primaryColor;
   background-color: $backgroundColor;

   &:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      top: 50%;
      left: 50%;
      will-change: transform;
      transition: transform .2s linear;
      transform: translate(-50%, -50%) scale(0);
      background-color: $primaryColor;
      border-radius: 50%;
   }
}

.wrapper:hover .checkmark {
   box-shadow: $focusShadow;
}

.radio:focus ~ .checkmark {
   box-shadow: $focusShadow;
}
.radio:checked ~ .checkmark:before {
   transform: translate(-50%, -50%) scale(1);
}