@import '../../css/variables.scss';

.wrapper {
   color: $primaryColor;
   position: relative;
   margin: 5px 0;
}

.title {
   background-color: $backgroundColor;
   display: inline;
   font-weight: bold;
   font-size: 14px;
   z-index: 1;
   position: relative;
   padding-right: 10px;
}

.line {
   position: absolute;
   top: 12px;
   width: 100%;
   height: 1px;
   background-color: $primaryColor;
   border-radius: 0.5px;
   left: 0;
   z-index: 0;
}