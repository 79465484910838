@import '../../css/variables.scss';
@import '../../css/mixins.scss';

.header {
   font-size: 200px;
   font-family: Arial;
   font-weight: bold;
   text-align: center;
   color: #ccc;

   @include screen('xs', 'sm') {
      font-size: 100px;
   }
}

.iconContainer {
   text-align: center;
}

.icon {
   text-align: center;
   margin-top: 25px;
   color: #ccc;
   font-size: 150px !important;
}

.content {
   text-align: center;
   font-size: 20px;
   line-height: 32px;
}

.address {
   margin: 0 4px;
   padding: 5px 10px;
   border-radius: 7.5px;
   background-color: $buttonHoverColor;
}