@import '../../css/variables.scss';

.button {
   position: absolute;
   top: 0;
   left: 0;
   width: 50px;
   height: 30px;
   background-color: $backgroundColor;
   transition: background-color .2s linear;
   border: none;
   display: flex;
   margin: 0;
   padding: 0;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   color: $fontColor;
   text-decoration: none;
   border-top-left-radius: 15px;

   &:hover {
      background-color: $buttonHoverColor;
   }

}