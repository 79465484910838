@import '../../css/variables.scss';

.wrapper {
   display: flex;
   flex-wrap: nowrap;
   overflow: hidden;
   height: 20px;
   margin-top: 5px;
}

.icon {
   flex-basis: 20px;
   width: 20px;
   height: 20px;
   margin-right: 5px;
   flex-shrink: 0;
   flex-grow: 0;
}

.replacementIcon {
   color: $primaryColor;
   font-size: 20px !important;
}

.replacementText {
   font-size: 14px;
   padding-left: 10px;
}