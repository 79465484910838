.sidebarIcon {
   width: 50px;
   height: 50px;
   position: relative;
   cursor: pointer;
   user-select: none;

   .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      will-change: transform;
      opacity: 0;
      transition: opacity .2s linear;

      &Active {
         @extend .icon;
         opacity: 1;
      }
   }
}