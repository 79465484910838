@import '../../css/variables.scss';

.wrapper {
   background-color: $backgroundColor;
   width: 100%;
   border-radius: 15px;
   border: 2px solid $primaryColor;
   box-shadow: $navShadow;
   position: relative;
}

.input {
   height: 50px;
   font-family: $font;
   padding: 15px;
   background-color: transparent;
   border: none;
   font-size: 18px;
   width: 100%;
   outline: none;
   padding-right: 50px;

   &::placeholder {
      color: $fontPlaceholderColor;
   }

   &:focus {
      box-shadow: $focusShadow;
      border-radius: 12px;
   }
}

.submit {
   position: absolute;
   border: none;
   background-color: transparent;
   font-size: 18px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: $fontPlaceholderColor;
   border-radius: 12px;
   cursor: pointer;
   width: 50px;
   height: 50px;
   right: 0;
   top: 0;

   .icon {
      color: inherit;
      font-size: 24px;
   }
}