@import '../../css/variables.scss';

.wrapper {
   text-align: center;
}

@mixin link {
   width: 50px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   font-size: 18px;
   user-select: none;

   .icon {
      font-size: inherit;
   }
}

.pagination {
   display: inline-flex;
   background-color: $backgroundColor;
   border-radius: 15px;
   overflow: hidden;
   box-shadow: $grayShadow;
   flex-wrap: wrap;
   justify-content: center;

   .link {
      @include link();
      transition: background-color .2s linear;
      color: $fontColor;

      &:hover {
         @extend .link;
         background-color: $buttonHoverColor;
      }

      &Active {
         @include link();
         background-color: $primaryColor;
         color: $backgroundColor;
      }

      &Disable {
         @include link();
         color: $fontPlaceholderColor;
      }
   }
}