.wrapper {
   position: relative;
   padding-top: 56.25%;
}

.iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}