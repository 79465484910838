@import '../../css/variables.scss';
@import '../../css/mixins.scss';

.wrapper {
   display: flex;
   position: relative;
   padding: 7.5px;
   left: -7.5px;
   width: calc(100% + 15px);
   transition: background-color .2s linear;
   border-radius: 7.5px;
   color: $fontColor !important;
   text-decoration: none;

   &:hover {
      background-color: $buttonHoverColor;
   }
}

.imageContainer {
   width: 64px;
   height: 64px;
   border-radius: 7.5px;
   flex-basis: 64px;
   flex-shrink: 0;
   flex-grow: 0;
   overflow: hidden;
   position: relative;

   .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
   }
}

.content {
   padding-left: 15px;
   flex-shrink: 0;
   flex-grow: 0;
   width: calc(100% - 64px);
}

.title {
   @include onelineDots();
   font-size: 18px;
   line-height: 22px;
}

.place {
   @include onelineDots();
   font-size: 12px;
   line-height: 15px;
}