@import '../../../css/variables.scss';
@import '../../../css/mixins.scss';

.wrapper {
   position: relative;
   padding-top: 100%;
   z-index: 0;
   overflow: hidden;
   transition: transform .2s linear, border-radius .2s linear, box-shadow .2s linear;
   box-shadow: 0 10px 30px -10px rgba(black, 0);

   .image {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      will-change: transform;
      transition: transform .2s linear;
      transform: scale(1.1);
      cursor: pointer;
   }

   @include screen('xl', 'xxl') {
      &:hover {
         transform: scale(1.1);
         border-radius: 5%;
         z-index: 1;
         box-shadow: $grayShadow;

         .image {
            transform: scale(1);
         }
      }
   }
}
