@import '../../css/variables.scss';

.wrapper {
   font-size: 14px;
   margin: 10px 0;
   display: flex;
   flex-wrap: nowrap;
}

.iconWrapper {
   flex-basis: 20px;
   width: 20px;
   height: 20px;
   display: flex;
   justify-content: coneter;
   align-items: coneter;

   .icon {
      font-size: 20px;
      color: $primaryColor;
   }
}

.content {
   padding-left: 10px;
   width: 100%;
}

.label {
   margin-right: 5px;
}

.text {
   margin-right: 5px;
   display: inline;
}

.link {
   font-weight: bold;
   margin-right: 5px;
   color: $primaryColor;
   display: inline-block;
}