// Colors
$primaryColor: #04ED45;
$backgroundColor: white;
$fontColor: black;
$fontPlaceholderColor: #B6CEBD;
$buttonHoverColor: rgb(241, 241, 241);
$inputColor: #E2FEE1;

// Gradients
$shadowGradient: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 0.01%, #000000 100%);

// Shadows
$navShadow: 0px 10px 20px rgba(41, 207, 48, 0.2);
$focusShadow: inset 0 0 0 2px rgba($primaryColor, 0.2);
$grayShadow: 0 10px 30px -10px rgba(black, 0.4);

// Typography
$font: 'Bellota', cursive;