@import '../../css/variables.scss';
@import '../../css/mixins.scss';

.wrapper {
   border-radius: 15px;
   border: 2px solid $primaryColor;
   background-color: $backgroundColor;
   display: flex;

   @include screen('xs', 'md') {
      flex-direction: column;
   }

   @include screen('xl', 'xl') {
      flex-direction: column;
   }
}

.content {
   position: relative;
   width: 100%;

   .padding {
      padding: 50px;
      padding-top: 25px;

      @include screen('xs', 'xs') {
         padding: 25px;
      }
   }
}

.sidebar {
   width: 300px;
   flex-basis: 300px;
   border-left: 2px solid $primaryColor;
   flex-shrink: 0;
   flex-grow: 0;
   padding: 25px;

   @include screen('xs', 'md') {
      border-left: none;
      width: 100%;
   }
   @include screen('xl', 'xl') {
      border-left: none;
      width: 100%;
   }

   .map {
      width: 100%;
   }

   .sidebarDesc {
      font-size: 12px;
      text-align: center;
      width: calc(100% + 50px);
      position: relative;
      left: -25px;
      border-bottom: 2px solid $primaryColor;
      padding-bottom: 25px;
      margin-bottom: 25px;
      padding-top: 25px;
   }

   h5 {
      margin-bottom: 10px;
   }
}

.list {
   padding: 0;
   padding-left: 20px;

   li {
      font-size: 14px;
      padding: 5px 0;
      margin: 0;
   }
}

.galleryContainer {
   padding-bottom: 15px;
   border-top: 2px solid $primaryColor;

   @include screen('xl', 'xl') {
      padding-bottom: 0;
      border-bottom: 2px solid $primaryColor;
   }
   @include screen('xs', 'md') {
      padding-bottom: 0;
      border-bottom: 2px solid $primaryColor;
   }
}