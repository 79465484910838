@import '../../css/variables.scss';

@keyframes rotate {
   from {
      transform: translate(-50%, -50%) rotate(0deg) ;
   }
   to {
      transform: translate(-50%, -50%) rotate(360deg);
   }
}

.wrapper {
   position: relative;
   width: 100%;
   height: 100%;
}

.img {
   position: relative;
   width: 100%;
   height: 100%;
   object-fit: cover;
   object-position: center;
   will-change: transform;
   transition: opacity .2s linear;

   &Hide {
      @extend .img;
      opacity: 0;
   }
}

.loader {
   position: absolute;
   top: 50%;
   left: 50%;

   width: 40px;
   height: 40px;

   border-top: 5px solid $primaryColor;
   border-bottom: 5px solid transparent;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-radius: 50%;

   animation-name: rotate;
   animation-duration: 1s;
   animation-iteration-count: infinite;
   animation-timing-function: linear;

   &Hide {
      display: none;
   }
}