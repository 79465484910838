@import '../../../css/variables.scss';

.wrapper {
   margin: 0;
   padding: 0;
}

.link {
   color: $backgroundColor;
   padding: 0 4px;
}