@import '../../css/variables.scss';
@import '../../css/mixins.scss';

.wrapper {
   border-radius: 15px;
   border: 2px solid $primaryColor;
   box-shadow: $navShadow;
   background-color: $backgroundColor;

   @include screen('xs', 'lg') {
      border-radius: 0;
      height: calc(100vh - 50px);
      border: none;
   }

   &Offline {
      @extend .wrapper;
      @include screen('xs', 'lg') {
         height: calc(100vh - 75px);
      }
   }
}