@import '../../css/variables.scss';

.wrapper {
   display: flex;
   align-items: center;
   padding: 5px 0;

   .icon {
      width: 24px;
      height: 24px;
      flex-basis: 24px;
      flex-shrink: 0;
      object-fit: contain;
   }

   .replacementIcon {
      color: $primaryColor;
   }

   .label {
      font-size: 14px;
      padding-left: 10px;
   }
}