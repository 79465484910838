@import '../../css/variables.scss';

@keyframes rotate {
   from {
      transform: rotate(0deg) ;
   }
   to {
      transform: rotate(360deg);
   }
}

.wrapper {
   position: relative;
   height: 200px;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.circle {
   will-change: transform;
   width: 40px;
   height: 40px;
   border-top: 5px solid $primaryColor;
   border-bottom: 5px solid transparent;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-radius: 50%;
   animation-name: rotate;
   animation-duration: 1s;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
}