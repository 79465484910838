@import '../../../css/variables.scss';

.wrapper {
   width: 100%;
   position: relative;
   padding-top: 100%;
   border-radius: 15px;
   overflow: hidden;
   box-shadow: $navShadow;
   display: block;
   margin-top: 30px;

   .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
   }

   .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      padding: 25px;
      text-align: center;
      will-change: transform;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: transform 0.3s ease-out, opacity 0.2s linear;
      opacity: 0;
      transform: scale(0);
      font-weight: bold;

      .title,
      .description,
      .button {
         margin: 7.5px 0;
      }

      .title {
         font-size: 24px;
      }
   }

   .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      z-index: 2;
      will-change: transform;
      transition: opacity 0.5s ease-out;
      opacity: 0;
   }

   &:hover {
      .background {
         opacity: 0.5;
      }
      .content {
         opacity: 1;
         transform: scale(1);
      }
   }
}

.button {
   padding: 10px;
   border-radius: 7.5px;
   color: $fontColor;
   background-color: $backgroundColor;
   font-size: 14px;
}

.separator {
   width: 30%;
   height: 2px;
   border-radius: 1px;
   background-color: $backgroundColor;
}

.bannerTitle {
   text-align: center;
   font-size: 12px;
   padding-top: 10px;
}
