@import '../../css/variables.scss';
@import '../../css/mixins.scss';

.wrapper {
   background-color: $backgroundColor;
   border-radius: 15px;
   position: relative;
   border: 2px solid $primaryColor;
   box-shadow: $navShadow;
   position: relative;
   overflow: hidden;

   @include screen('xs', 'lg') {
      height: calc(100vh - 50px);
      border-radius: 0;
      border: none;
   }

   &Offline {
      @extend .wrapper;
      @include screen('xs', 'lg') {
         height: calc(100vh - 75px);
      }
   }
}

.searchContainer {
   padding: 25px;
   position: absolute;
   top: 0;
   left: 0;

   &Active {
      @extend .searchContainer;
      position: relative;
   }
}

.icon {
   font-size: inherit !important;
   margin-left: 5px !important;
   font-size: 14px !important;
}

.filterContainer {
   padding: 25px;
   position: absolute;
   top: 0;
   left: 0;
   will-change: transform;
   transition: transform .2s ease-out;
   background-color: $backgroundColor;
   width: 100%;
   transform: translateX(100%);
   min-height: 300px;
   height: 100%;

   &Active {
      @extend .filterContainer;
      position: relative;
      transform: translateX(0);
   }
}

.sortContainer {
   padding: 25px;
   position: absolute;
   top: 0;
   left: 0;
   will-change: transform;
   transition: transform .2s ease-out;
   background-color: $backgroundColor;
   width: 100%;
   transform: translateX(100%);
   min-height: 300px;
   height: 100%;

   &Active {
      @extend .sortContainer;
      position: relative;
      transform: translateX(0);
   }
}

.scrollPanel {
   max-height: calc(100vh - 300px);
   width: calc(100% + 15px);
   position: relative;
   left: -7.5px;
   padding: 0 7.5px;

   .space {
      @include screen('xs', 'lg') {
         height: 25px;
      }
   }

   @include screen('xs', 'lg') {
      max-height: calc(100vh - 110px);
   }

   &Offline {
      @extend .scrollPanel;
      @include screen('xs', 'lg') {
         max-height: calc(100vh - 110px - 25px);
      }
   }

   &Search {
      @extend .scrollPanel;

      @include screen('xs', 'lg') {
         max-height: calc(100vh - 185px);
      }

      &Offline {
         @extend .scrollPanel;

         @include screen('xs', 'lg') {
            max-height: calc(100vh - 185px - 25px);
         }
      }

   }
}


.norecords {
   font-size: 14px;
   padding: 10px 0;
   text-align: center;
   color: $fontPlaceholderColor;
   border-top: 1px solid $fontPlaceholderColor;
   border-bottom: 1px solid $fontPlaceholderColor;
   margin: 15px 0;
}