.wrapper {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   font-size: 12px;
   padding: 10px 0;

   span {
      font-size: inherit;
      margin-left: 5px;
   }
}