@import '../../css/mixins.scss';

.container {
   max-width: 800px;
   margin: 0 auto;
}

.grid {
   margin-top: 25px;
   display: grid;
   grid-template-areas:
      "box1 box1 box2 box2 box3 box3 box3"
      "box4 box4 box4 box4 box3 box3 box3";
   grid-gap: 25px;
   grid-template-rows: 210px 260px;

   @for $i from 1 to 5 {
      a:nth-child(#{$i}) {
         grid-area: box#{$i};
      }
   }

   @include screen ('sm', 'md') {
      grid-template-areas:
         "box1 box2"
         "box3 box4";
      grid-template-rows: 210px 210px;
   }

   @include screen ('xs', 'xs') {
      grid-template-areas:
         "box1"
         "box2"
         "box3"
         "box4";
      grid-template-rows: 210px 210px 210px 210px;
   }
}