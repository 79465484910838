@import '../../css/variables.scss';
@import '../../css/mixins.scss';

@mixin sidebarContainerMobile($direction, $offline) {
   @include screen('xs', 'lg') {
      position: fixed;
      top: 50px;
      z-index: 11;
      padding: 0;
      height: calc(100vh - 50px);
      max-width: 100vw;
      will-change: transform;
      transition: transform .3s ease-out;

      @if($offline == 1) {
         top: 75px;
         height: calc(100vh - 75px);
      }

      @if($direction == 'left') {
         left: 0;
         transform: translateX(-120%);
      }
      @if($direction == 'right') {
         right: 0;
         transform: translateX(120%);
      }
   }
}

.wrapper {
   display: flex;
   z-index: 1;
   min-height: 100vh;
}

.sidebarContainer {
   width: 350px;
   flex-grow: 0;
   flex-shrink: 0;
   padding: 25px;
   padding-top: 200px;
   position: relative;
   z-index: 1;

   &Left {
      @extend .sidebarContainer;
      padding-right: 0;
      @include sidebarContainerMobile('left', 0);

      &Offline {
         @extend .sidebarContainer;
         padding-right: 0;
         @include sidebarContainerMobile('left', 1);
      }

      &Active {
         @extend .sidebarContainer;
         padding-right: 0;
         @include sidebarContainerMobile('left', 0);
         @include screen('xs', 'lg') {
            transform: translateX(0);
         }

         &Offline {
            @extend .sidebarContainer;
            padding-right: 0;
            @include sidebarContainerMobile('left', 1);
            @include screen('xs', 'lg') {
               transform: translateX(0);
            }
         }
      }
   }

   &Right {
      @extend .sidebarContainer;
      padding-left: 0;
      @include sidebarContainerMobile('right', 0);

      &Offline {
         @extend .sidebarContainer;
         padding-left: 0;
         @include sidebarContainerMobile('right', 1);
      }

      &Active {
         @extend .sidebarContainer;
         padding-left: 0;
         @include sidebarContainerMobile('right', 0);
         @include screen('xs', 'lg') {
            transform: translateX(0);
         }

         &Offline {
            @extend .sidebarContainer;
            padding-left: 0;
            @include sidebarContainerMobile('right', 1);
            @include screen('xs', 'lg') {
               transform: translateX(0);
            }
         }
      }
   }
}

.container {
   width: 100%;
   padding: 25px;
   padding-top: 200px;
   position: relative;
   z-index: 2;
}