@import '../../css/variables.scss';
@import '../../css/mixins.scss';

.wrapper {
   position: fixed;
   background-color: $backgroundColor;
   width: 100%;
   height: 50px;
   top: 0;
   left: 0;
   z-index: 10;
   box-shadow: $grayShadow;
   display: flex;
   justify-content: space-between;

   @include screen('xl', 'xxl') {
      display: none;
   }

   &Offline {
      @extend .wrapper;
      top: 25px;
   }
}

.menuIcon {
   width: 50px;
   height: 50px;
   position: relative;
   cursor: pointer;

   .line {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 30px;
      height: 2px;
      border-radius: 1px;
      background-color: $fontColor;
      will-change: transform;
      transition: transform .3s ease-out;

      &:nth-child(1) {
         transform: translate(-50%, -10px);
      }
      &:nth-child(2) {
         transform: translate(-50%, -50%);
      }
      &:nth-child(3) {
         transform: translate(-50%, 8px);
      }
   }

   &Active {
      @extend .menuIcon;

      .line:nth-child(1) {
         transform: translate(-50%, -50%) rotate(-45deg);
      }
      .line:nth-child(2) {
         transform: translate(-50%, -50%) rotate(-45deg);
      }
      .line:nth-child(3) {
         transform: translate(-50%, -50%) rotate(45deg);
      }
   }
}

.logo {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.shadow {
   position: fixed;
   z-index: 8;
   background-color: rgba(black, 0.3);
   width: 100%;
   height: 100vh;
   top: 0;
   left: 0;
   pointer-events: none;
   will-change: transform;
   transition: opacity .2s linear;
   opacity: 0;

   &Active {
      @extend .shadow;
      opacity: 1;
      pointer-events: all;
   }

   @include screen('xl', 'xxl') {
      display: none;
   }
}