@import "../../css/variables.scss";
@import "../../css/mixins.scss";

.wrapper {
   background-color: $backgroundColor;
   border: 2px solid $primaryColor;
   border-radius: 15px;
   padding: 25px;
   padding-top: 0;
   box-shadow: $navShadow;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
   grid-gap: 25px;

   @include screen("xs", "xs") {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
   }

   h1 {
      grid-column: 1/-1;
   }
}

.letter {
   font-size: 24px;
   font-weight: bold;
   grid-column: 1/-1;
   line-height: 24px;
   color: $primaryColor;
}
