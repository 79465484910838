@import '../../../css/variables.scss';

.wrapper {
   padding: 0;
   margin: 0;
}

.link {
   display: block;
   color: $fontColor;
   font-size: 18px;
   line-height: 1.2em;
   padding: 14.2px 0;
   text-decoration: none;
   text-align: center;
   transition: color .1s ease-out;

   &:hover {
      color: $primaryColor;
   }

   &Active {
      @extend .link;
      color: $primaryColor;
      position: relative;
      font-weight: bold;

      &:before {
         content: "";
         position: absolute;
         top: 50%;
         right: -35px;
         width: 0;
         height: 0;
         transform: rotate(-45deg) translateY(-50%);
         border-top: 6px solid $primaryColor;
         border-left: 6px solid $primaryColor;
         border-right: 6px solid transparent;
         border-bottom: 6px solid transparent;
      }
   }
}

