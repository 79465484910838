@import url('https://fonts.googleapis.com/css2?family=Bellota:wght@300;400;700&display=swap');

body {
   margin: 0;
   padding: 0;
   font-size: 18px;
   font-family: 'Bellota', cursive;
}

*, *:before, *:after {
   box-sizing: border-box;
}

h1 {
   font-weight: normal;
   font-size: 36px;
}
h2 {
   font-weight: normal;
   font-size: 32px;
}
h3 {
   font-weight: normal;
   font-size: 28px;
}
h4 {
   font-weight: normal;
   font-size: 24px;
}
h5 {
   font-weight: normal;
   font-size: 20px;
}
h6 {
   font-weight: normal;
   font-size: 16px;
}

@media (max-width: 575px) {
   h1 {
      font-size: 28px;
   }
   h2 {
      font-size: 25px;
   }
   h3 {
      font-size: 21px;
   }
   h4 {
      font-size: 18px;
   }
   h5 {
      font-size: 16px;
   }
   h6 {
      font-size: 14px;
   }
}

p {
   font-size: 18px;
}