@import '../../css/variables.scss';
@import '../../css/mixins.scss';

.wrapper {
   max-width: 800px;
   margin: 0 auto;
   border-radius: 15px;
   border: 2px solid $primaryColor;
   box-shadow: $navShadow;
   overflow: hidden;
   background-color: $backgroundColor;
}

.image {
   width: 100%;
   height: 250px;
}

.content {
   padding: 50px;
   position: relative;
   top: -25px;

   @include screen('xs', 'xs') {
      padding: 25px;
   }

   a {
      color: $primaryColor;
      font-weight: bold;
   }

   ol, ul {
      padding-left: 25px;

      ol, ul {
         margin-top: 5px;
         margin-bottom: 15px;
         li {
            margin: 5px 0;
         }
      }
   }

   li {
      margin: 15px 0;
   }
}

img {
   max-width: 100%;
}