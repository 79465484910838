@import '../../../css/variables.scss';

.wrapper {
   color: $fontColor;
   font-size: 18px;
   text-decoration: none;
   padding: 14px 0;
   display: block;
   transition: color .1s linear;

   &:hover {
      color: $primaryColor;
   }
}